/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const validateAndPost = /* GraphQL */ `
  mutation ValidateAndPost(
    $firstname: String
    $lastname: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $phonenumber: String
    $email: String
    $captchakey: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
    $_version: Int
    $_deleted: Boolean
    $_lastChangedAt: AWSTimestamp
  ) {
    validateAndPost(
      firstname: $firstname
      lastname: $lastname
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phonenumber: $phonenumber
      email: $email
      captchakey: $captchakey
      createdAt: $createdAt
      updatedAt: $updatedAt
      _version: $_version
      _deleted: $_deleted
      _lastChangedAt: $_lastChangedAt
    ) {
      statusCode
      message
      __typename
    }
  }
`;
export const createUntitledModel = /* GraphQL */ `
  mutation CreateUntitledModel(
    $input: CreateUntitledModelInput!
    $condition: ModelUntitledModelConditionInput
  ) {
    createUntitledModel(input: $input, condition: $condition) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUntitledModel = /* GraphQL */ `
  mutation UpdateUntitledModel(
    $input: UpdateUntitledModelInput!
    $condition: ModelUntitledModelConditionInput
  ) {
    updateUntitledModel(input: $input, condition: $condition) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUntitledModel = /* GraphQL */ `
  mutation DeleteUntitledModel(
    $input: DeleteUntitledModelInput!
    $condition: ModelUntitledModelConditionInput
  ) {
    deleteUntitledModel(input: $input, condition: $condition) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMarcSubmission = /* GraphQL */ `
  mutation CreateMarcSubmission(
    $input: CreateMarcSubmissionInput!
    $condition: ModelMarcSubmissionConditionInput
  ) {
    createMarcSubmission(input: $input, condition: $condition) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      clicksendsubmissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateMarcSubmission = /* GraphQL */ `
  mutation UpdateMarcSubmission(
    $input: UpdateMarcSubmissionInput!
    $condition: ModelMarcSubmissionConditionInput
  ) {
    updateMarcSubmission(input: $input, condition: $condition) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      clicksendsubmissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteMarcSubmission = /* GraphQL */ `
  mutation DeleteMarcSubmission(
    $input: DeleteMarcSubmissionInput!
    $condition: ModelMarcSubmissionConditionInput
  ) {
    deleteMarcSubmission(input: $input, condition: $condition) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      clicksendsubmissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createMarcApplication = /* GraphQL */ `
  mutation CreateMarcApplication(
    $input: CreateMarcApplicationInput!
    $condition: ModelMarcApplicationConditionInput
  ) {
    createMarcApplication(input: $input, condition: $condition) {
      id
      messageid
      datetime
      httpcode
      status
      submid
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMarcApplication = /* GraphQL */ `
  mutation UpdateMarcApplication(
    $input: UpdateMarcApplicationInput!
    $condition: ModelMarcApplicationConditionInput
  ) {
    updateMarcApplication(input: $input, condition: $condition) {
      id
      messageid
      datetime
      httpcode
      status
      submid
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMarcApplication = /* GraphQL */ `
  mutation DeleteMarcApplication(
    $input: DeleteMarcApplicationInput!
    $condition: ModelMarcApplicationConditionInput
  ) {
    deleteMarcApplication(input: $input, condition: $condition) {
      id
      messageid
      datetime
      httpcode
      status
      submid
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const initiateApplication = /* GraphQL */ `
  mutation InitiateApplication($id: String!) {
    initiateApplication(id: $id) {
      statusCode
      message
      __typename
    }
  }
`;
