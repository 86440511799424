/** @format */

import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listMarcSubmissions } from "./graphql/queries";
import { initiateApplication } from "./graphql/mutations";
import EnhancedSubmissionsTable from "./subTable";

const DataTable = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [userId, setUserId] = useState(null);
  const [submitting, setSubmitting] = useState(null);

  const handleSubmit = async (id) => {
    setSubmitting(id);
    console.log("Hello, this is the id");
    console.log(id);
    try {
      await API.graphql({
        query: initiateApplication,
        variables: { id: id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // Optionally, you can refetch the submissions or update the local state
      // to reflect any changes made by the initiateApplication mutation
      alert(`Application initiated for submission ${id}`);
    } catch (err) {
      console.error("Error initiating application:", err);
      alert(
        `Failed to initiate application for submission ${id}. Please try again.`
      );
    } finally {
      setSubmitting(null);
    }
  };

  useEffect(() => {
    checkUserAuth();
  }, []);

  const checkUserAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];
      setUserGroups(groups);
      setUserId(user.username);
      fetchSubmissions(groups, user.username);
    } catch (err) {
      console.error("Error checking user authentication:", err);
      setError("Authentication error. Please sign in.");
      setLoading(false);
    }
  };

  const fetchSubmissions = async (groups, username) => {
    let variables = {};
    let filter = {};

    if (groups.includes("admins") || groups.includes("MARC_headstart")) {
      // Fetch all submissions for admins and MARC_headstart
      variables = {
        // limit: 1000, // Adjust this based on your needs
      };
    } else {
      variables = {
        // limit: 1000, // Adjust this based on your needs
      };
    }

    try {
      const submissionData = await API.graphql({
        query: listMarcSubmissions,
        variables: variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      //   note: theres a setting somewhere in the aws console which sets
      // the default auth mode. as of 7/5/2024 it was set to api_key, since
      // that is what we used for the public facing portal. If you change it,
      // you wouldnt need to specify authmode here
      setSubmissions(submissionData.data.listMarcSubmissions.items);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching submissions:", err);
      setError("Error fetching data. Please try again.");
      setLoading(false);
    }
  };

  const isAdmin = userGroups.includes("admins");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <EnhancedSubmissionsTable
        submissions={submissions}
        isAdmin={isAdmin}
        handleSubmit={handleSubmit}
      />

      {/*       
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            {isAdmin && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission) => (
            <tr key={submission.id}>
              <td>{submission.id}</td>
              <td>{submission.firstname}</td>
              <td>{submission.lastname}</td>
              <td>{submission.email}</td>
              {isAdmin && (
                <td>
                  <button
                    onClick={() => handleSubmit(submission.id)}
                    disabled={submitting === submission.id}
                  >
                    {submitting === submission.id ? "Submitting..." : "Submit"}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default DataTable;
