/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { BottomBar } from "./Main.js";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./App.js";
import { PieChart } from "@mui/x-charts/PieChart";
import Box from "@mui/material/Box";
import { flexbox } from "@mui/system";
import { withAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import Submit from "./Submit";
import DataTable from "./ViewSubmissions.js";

const signOut = async () => {
  try {
    await Auth.signOut();
    // Redirect or perform other actions after sign-out
  } catch (error) {
    console.log("Error signing out:", error);
  }
};

const SignOutLink = () => {
  return <button onClick={signOut}>Sign Out</button>;
};

const colorpalette = [
  "#4731d4",
  "#bed431",
  "#316dd4",
  "#9831d4",
  "#d431be",
  "#d44731",
];

function Partners() {
  const [State, setActiveState] = React.useState({
    showterms: false,
    content: "",
  });

  return (
    <div id="text_divs" style={{ padding: 20, paddingTop: 40 }}>
      <Authenticator>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          Welcome to our partner login site
          <Submit initialRenderMode="marc" />
          <DataTable />
          <SignOutLink />
        </ThemeProvider>
      </Authenticator>
    </div>
  );
}

export default Partners;
