/** @format */

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const EnhancedSubmissionsTable = ({ submissions, isAdmin, handleSubmit }) => {
  const [filters, setFilters] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
  });
  const [submitting, setSubmitting] = useState(null);

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({ ...prev, [column]: value }));
  };

  const filteredSubmissions = submissions.filter((submission) =>
    Object.keys(filters).every((key) =>
      submission[key]
        .toString()
        .toLowerCase()
        .includes(filters[key].toLowerCase())
    )
  );

  const handleSubmitWrapper = async (id) => {
    setSubmitting(id);
    await handleSubmit(id);
    setSubmitting(null);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Typography variant="h6" sx={{ p: 2 }}>
        Your Submissions
      </Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              {isAdmin && <TableCell>CS Submissions</TableCell>}
              {isAdmin && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.keys(filters).map((key) => (
                <TableCell key={key}>
                  <TextField
                    size="small"
                    placeholder={`Search ${key}`}
                    value={filters[key]}
                    onChange={(e) => handleFilterChange(key, e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              ))}
              {isAdmin && <TableCell />}
            </TableRow>
            {filteredSubmissions.map((submission) => (
              <TableRow key={submission.id}>
                <TableCell>{submission.id}</TableCell>
                <TableCell>{submission.firstname}</TableCell>
                <TableCell>{submission.lastname}</TableCell>
                <TableCell>{submission.email}</TableCell>
                {isAdmin && (
                  <TableCell>{submission.clicksendsumbissions}</TableCell>
                )}
                {isAdmin && (
                  <TableCell>
                    <IconButton
                      onClick={() => handleSubmitWrapper(submission.id)}
                      disabled={submitting === submission.id}
                      color="primary"
                      size="small"
                    >
                      <Typography
                        variant="caption"
                        style={{ fontSize: "0.7rem" }}
                      >
                        {submitting === submission.id ? "..." : "Submit"}
                      </Typography>
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EnhancedSubmissionsTable;
