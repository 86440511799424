/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { BottomBar } from "./Main.js";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./App";

import { terms, privacy, EULA, use, disclaimer } from "./strings.js";

function NotAvailable() {
  const [State, setActiveState] = React.useState({
    showterms: false,
    content: "",
  });

  const enabledialog = () => {
    // Just enable the dialog to be shown
    let dialog = { showterms: true };
    setActiveState((State) => ({
      ...State,
      ...dialog,
    }));
  };

  const disabledialog = () => {
    // Just disable the button to be clicked
    let dialog = { showterms: false };
    setActiveState((State) => ({
      ...State,
      ...dialog,
    }));
  };

  const setContent = (content) => {
    if (content == "terms") {
      let x = { content: terms, title: "Terms of Service" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "privacy") {
      let x = { content: privacy, title: "Privacy Policy" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "EULA") {
      let x = { content: EULA, title: "END-USER LICENSE AGREEMENT (EULA)" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "use") {
      let x = { content: use, title: "ACCEPTABLE USE POLICY" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "disclaimer") {
      let x = { content: disclaimer, title: "DISCLAIMER" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
  };

  const enabledialogv2 = (content) => {
    // Just enable the dialog to be shown
    setContent(content);
    let dialog = { showterms: true };
    setActiveState((State) => ({
      ...State,
      ...dialog,
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <div id="text_divs" style={{ padding: 20, paddingTop: 40 }}>
        <Typography variant="h6" color="inherit">
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              {" "}
              Please contact us at support@freethrow.ai to apply
            </li>
            <br />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Typography>
      </div>

      <BottomBar />
    </ThemeProvider>
  );
}

export default NotAvailable;
