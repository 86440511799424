/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUntitledModel = /* GraphQL */ `
  query GetUntitledModel($id: ID!) {
    getUntitledModel(id: $id) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUntitledModels = /* GraphQL */ `
  query ListUntitledModels(
    $filter: ModelUntitledModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUntitledModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        address1
        address2
        city
        state
        zip
        phonenumber
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUntitledModels = /* GraphQL */ `
  query SyncUntitledModels(
    $filter: ModelUntitledModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUntitledModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstname
        lastname
        address1
        address2
        city
        state
        zip
        phonenumber
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMarcSubmission = /* GraphQL */ `
  query GetMarcSubmission($id: ID!) {
    getMarcSubmission(id: $id) {
      id
      firstname
      lastname
      address1
      address2
      city
      state
      zip
      phonenumber
      email
      clicksendsubmissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listMarcSubmissions = /* GraphQL */ `
  query ListMarcSubmissions(
    $filter: ModelMarcSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarcSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        address1
        address2
        city
        state
        zip
        phonenumber
        email
        clicksendsubmissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMarcSubmissions = /* GraphQL */ `
  query SyncMarcSubmissions(
    $filter: ModelMarcSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMarcSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstname
        lastname
        address1
        address2
        city
        state
        zip
        phonenumber
        email
        clicksendsubmissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMarcApplication = /* GraphQL */ `
  query GetMarcApplication($id: ID!) {
    getMarcApplication(id: $id) {
      id
      messageid
      datetime
      httpcode
      status
      submid
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMarcApplications = /* GraphQL */ `
  query ListMarcApplications(
    $filter: ModelMarcApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarcApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageid
        datetime
        httpcode
        status
        submid
        cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMarcApplications = /* GraphQL */ `
  query SyncMarcApplications(
    $filter: ModelMarcApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMarcApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        messageid
        datetime
        httpcode
        status
        submid
        cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
