/** @format */

import * as React from "react";
import { useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import QuizIcon from "@mui/icons-material/Quiz";
import InfoIcon from "@mui/icons-material/Info";
import { BottomBar } from "./Main";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";
// import { List, ListItem } from "@material-ui/core";

import ApprovalIcon from "@mui/icons-material/Approval";
import DialogActions from "@mui/material/DialogActions";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { Link as Lnk } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { createMarcSubmission, validateAndPost } from "./graphql/mutations";
import { Oval } from "react-loader-spinner";
import { defaultTheme } from "./App";
import ReCAPTCHA from "react-google-recaptcha";
import { terms, privacy } from "./strings.js";
import imageSource from "./logos.png";

import {
  Card,
  CardContent,
  Grid,
  CardActions,
  CardActionArea,
} from "@mui/material";

import { Auth } from "aws-amplify";

async function getUserGroups() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    // 'cognito:groups' is a custom claim containing the user's groups
    console.log("==================");
    console.log("User groups:", groups);

    return groups; // Return groups array
  } catch (error) {
    console.error("Error fetching user groups:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
}

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://freethrow.ai/">
        FreeThrow LLC.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// Card component example
const CustomCard = ({ title, content, actiontxt, pagelink }) => {
  return (
    <Card>
      <CardActionArea component={Lnk} to={pagelink}>
        <CardContent>
          <ListItemIcon>
            <ApprovalIcon sx={{ color: "black" }} />
          </ListItemIcon>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography color="text.secondary">{content}</Typography>

          {/* <CardActions>
          <Button component={Lnk} to={pagelink} color="primary">
            {actiontxt}
          </Button>
          {/* <Button size="small">{actiontxt}</Button> */}
          {/*</CardActions> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const SubmitCard = ({ title, actiontxt, pagelink }) => {
  return (
    <Card>
      <CardActionArea component={Lnk} to={pagelink}>
        <CardContent>
          <ListItemIcon>
            <ApprovalIcon sx={{ color: "black" }} />
          </ListItemIcon>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography color="text.secondary">
            Easily apply to multiple public benefit programs at the same time:
            <List component="ol">
              <ListItem component="li" disablePadding="true">
                <ListItemText
                  primary="1. Submit your contact information
"
                />
              </ListItem>
              <ListItem component="li" disablePadding="true">
                <ListItemText primary="2. FreeThrow initiates applications on your behalf" />
              </ListItem>
              <ListItem component="li" disablePadding="true">
                <ListItemText primary="3. A state representative should contact you to finalize your applications (this process typically takes 4-6 weeks)." />
              </ListItem>
              {/* Add more ListItem components for additional items */}
            </List>
          </Typography>

          {/* <CardActions>
          <Button component={Lnk} to={pagelink} color="primary">
            {actiontxt}
          </Button>
          {/* <Button size="small">{actiontxt}</Button> */}
          {/*</CardActions> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

// Row component containing three cards
const CardRow = () => {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={10}>
        <SubmitCard
          title="Submit"
          actiontxt="Submit your information"
          pagelink="/aboutus"
        />
      </Grid>
      <Grid item xs={5}>
        <CustomCard
          title="Learn"
          content="Find out more about how FreeThrow can help you save money and time"
          actiontxt="Learn More"
          pagelink="faqs"
        />
      </Grid>
      <Grid item xs={5}>
        <CustomCard
          title="Learn"
          content="Find out more about how FreeThrow can help you save money and time"
          actiontxt="Learn More"
          pagelink="faqs"
        />
      </Grid>
    </Grid>
  );
};

const steps = ["Name", "Contact", "Submit"];
// TODO: get this .env file to work plz
// const siteKey = process.env.REACT_APP_SITE_KEY;
const siteKey = "6Le0RHgnAAAAALNgPOXlJT4bO50qrndPZCTeXjYp";

function getStepContent(obj) {
  if (obj.state.loading) {
    return (
      <div>
        {" "}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Oval
            height={80}
            width={80}
            color="#344954"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#9c27b0"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </Backdrop>
      </div>
    );
  } else {
    switch (obj.step) {
      case 0:
        return <AddressForm state={obj.state} fcn={obj.fcn} />;
      case 1:
        return <PaymentForm state={obj.state} fcn={obj.fcn} />;
      case 2:
        return <Review state={obj.state} fcn={obj.fcn} />;
      default:
        throw new Error("Unknown step");
    }
  }
}

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme({
//   typography: {
//     allVariants: {
//       fontFamily: 'Raleway',
//     },
//   },
//   palette: {
//     mode: "dark",

//     primary: {
//       main: "#344954",
//     },
//     secondary: {
//       main: "#9c27b0",
//     },
//   },
// });

// TODO: expand on this to make sure entry validation works
// email address, check for @ and .com or whatever
// name etc, check that its not just a bunch of spaces or whatever (python strip?)
const formkeys = [
  "firstName",
  "lastName",
  "city",
  "stateUS",
  "address1",
  "address2",
  "emailAddress",
  "phoneNumber",
  "zipCode",
];

let initialform = {};
for (let key of formkeys) {
  initialform[key] = "";
}

let initialerrorstate = {};
for (let key of formkeys) {
  initialerrorstate[key + "_error"] = false;
}

const zipre = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");

// const emailre = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");
// const emailre = new RegExp("\b[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}\b");
const emailre = new RegExp("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$");

function validateFormEntries(appState) {
  let errormessages = [];
  let firstName = appState["firstName"];
  let address1 = appState["address1"];
  let city = appState["city"];
  let state = appState["stateUS"];
  let email = appState["emailAddress"];
  let zip = appState["zipCode"];
  let phone = appState["phoneNumber"];
  if (appState.activeStep == 0) {
    if (firstName.trim().length == 0) {
      errormessages.push(["A first name is required"]);
      appState["firstName_error"] = true;
    } else {
      appState["firstName_error"] = false;
    }
    let lastName = appState["lastName"];
    if (lastName.trim().length == 0) {
      errormessages.push(["A last name is required"]);
      appState["lastName_error"] = true;
    } else {
      appState["lastName_error"] = false;
    }
    if (address1.trim().length == 0) {
      errormessages.push(["An address is required"]);
      appState["address1_error"] = true;
    } else {
      appState["address1_error"] = false;
    }
    if (city.trim().length == 0) {
      errormessages.push(["A city is required"]);
      appState["city_error"] = true;
    } else {
      appState["city_error"] = false;
    }
    if (state.trim().length == 0) {
      errormessages.push(["A state is required"]);
      appState["stateUS_error"] = true;
    } else {
      appState["stateUS_error"] = false;
    }
    if (zip.trim().length == 0) {
      errormessages.push(["A zip code is required"]);
      appState["zipCode_error"] = true;
    }
    if (!zipre.test(zip.trim())) {
      errormessages.push(["please enter a valid zipcode"]);
      appState["zipCode_error"] = true;
    } else {
      appState["zipCode_error"] = false;
    }
    if (errormessages.length == 0) {
      return [true, errormessages];
    } else {
      return [false, errormessages];
    }
  } else if (appState.activeStep == 1) {
    if (!emailre.test(email.trim())) {
      errormessages.push(["please enter a valid email address"]);
      appState["emailAddress_error"] = true;
    } else {
      appState["emailAddress_error"] = false;
    }

    if (phone.length != 14) {
      errormessages.push(["please enter a valid 10 digit phone number"]);
      appState["phoneNumber_error"] = true;
    } else {
      appState["phoneNumber_error"] = false;
    }

    if (errormessages.length == 0) {
      return [true, errormessages];
    } else {
      return [false, errormessages];
    }
  } else if (appState.activeStep == 2) {
    return [true, errormessages];
  }
}

async function postDatav2(props) {
  // console.log(props)
  //   let datatopost = {
  //     firstname: props.firstName,
  //     lastname: props.lastName,
  //     address1: props.address1,
  //     address2: props.address2,
  //     city: props.city,
  //     state: props.stateUS,
  //     zip: props.zipCode,
  //     phonenumber: props.phoneNumber,
  //     email: props.emailAddress,
  //     captchakey: props.captchakey,
  //   };
  // console.log(datatopost)
  // console.log(typeof props.captchakey)
  // const apiData = await API.graphql(graphqlOperation(validateAndPost, {firstname: props.firstName, lastname: "abc"}))
  // const apiData = await API.graphql(graphqlOperation(validateAndPost, {datatopost}))
  const apiData = await API.graphql(
    graphqlOperation(validateAndPost, {
      firstname: props.firstName,
      lastname: props.lastName,
      address1: props.address1,
      address2: props.address2,
      city: props.city,
      state: props.stateUS,
      zip: props.zipCode,
      phonenumber: props.phoneNumber,
      email: props.emailAddress,
      captchakey: props.captchakey,
    })
  );

  return apiData;
}

async function marcPostData(props) {
  const input = {
    firstname: props.firstName,
    lastname: props.lastName,
    address1: props.address1,
    address2: props.address2,
    city: props.city,
    state: props.stateUS,
    zip: props.zipCode,
    phonenumber: props.phoneNumber,
    email: props.emailAddress,
  };

  const apiData = await API.graphql({
    query: createMarcSubmission,
    variables: { input },
    authMode: "AMAZON_COGNITO_USER_POOLS", // Optionally specify auth mode
  });

  return apiData;
}

function ConfirmSubmit(props) {
  let appState = props.appState;
  // console.log(appState)
  if (appState.lastStatus == "success") {
    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          {appState.firstName} {appState.lastName}, thank you for submitting
          your information.
        </Typography>
        <Typography variant="subtitle1">
          A confirmation email will be sent to your email address at{" "}
          {appState.emailAddress} within 24 hours. Please don't hesitate to
          reach out with any questions at support@freethrow.ai.
        </Typography>
      </React.Fragment>
    );
  } else {
    return (
      <Typography variant="h5">
        {" "}
        Something went wrong with your submission. Please try again by
        refreshing the page and re-entering your information. If the issue
        persists, please contact us at support@freethrow.ai{" "}
      </Typography>
    );
  }
}

// TODO: split out into two state variables, one is step, other is form info
export default function Submit({ initialRenderMode }) {
  // getUserGroups();
  const [renderMode, setRenderMode] = React.useState(initialRenderMode);
  const location = useLocation();

  React.useEffect(() => {
    // Update renderMode based on the current route
    if (location.pathname === "/submit") {
      setRenderMode("public");
    } else {
      setRenderMode("marc");
    }
  }, [location]);

  let postData;
  if (renderMode == "public") {
    postData = postDatav2;
  } else {
    postData = marcPostData;
  }
  const captchaRef = React.useRef(null);
  const [appState, setActiveState] = React.useState({
    activeStep: 0,
    text: "initial text",
    msg: [],
    loading: false,
    disabledButton: false,
    termsModal: false,
    ...initialform,
    ...initialerrorstate,
  });

  function showCaptcha(obj) {
    if (obj.step == steps.length - 1) {
      return (
        <ReCAPTCHA
          sitekey={siteKey}
          ref={captchaRef}
          size="compact"
          onChange={enableButton}
          onExpired={disableButton}
        />
      );
    }
  }

  const callAuthLambda = async (token) => {
    try {
      const response = await API.graphql(
        graphqlOperation(validateAndPost, {
          firstname: "chris",
          lastname: "abc",
        })
      );
      // console.log('Lambda Response:', response);
      return response;
    } catch (error) {
      console.error("Error calling Lambda function:", error);
    }
  };

  const enableButton = () => {
    // Just enable the button to be clicked
    let button = { disabledButton: false };
    setActiveState((appstate) => ({
      ...appState,
      ...button,
    }));
  };

  const disableButton = () => {
    // Just disable the button to be clicked
    let button = { disabledButton: true };
    setActiveState((appstate) => ({
      ...appState,
      ...button,
    }));
  };

  const enableModal = () => {
    // Just enable the modal to be shown
    let modal = { termsModal: true };
    setActiveState((appstate) => ({
      ...appState,
      ...modal,
    }));
  };

  const disableModal = () => {
    // Just disable the button to be clicked
    let modal = { termsModal: false };
    setActiveState((appstate) => ({
      ...appState,
      ...modal,
    }));
  };

  const handleNext = () => {
    let [formIsFilled, message] = validateFormEntries(appState);
    let errorMessage = { msg: message };
    if (formIsFilled) {
      let newStep = { activeStep: appState.activeStep + 1 };
      if (newStep.activeStep == steps.length) {
        // Submit information to API in this case
        // Want to add a line before the one below to show a pending screen
        let loading = { loading: true };
        setActiveState((appState) => ({
          ...appState,
          ...loading,
        }));
        let data_to_post = {
          ...appState,
          ...{ captchakey: captchaRef.current.getValue() },
        };
        // console.log(data_to_post);
        let response = postData(data_to_post)
          .then((data) => {
            let loading = { loading: false };
            let lastResponse = { lastAPIResponse: data };
            // console.log(data);
            // let lastStatus = {lastStatus: "success"}
            // TODO: fix parsing of json below
            let lastStatus = {};
            console.log("++++++++++");
            console.log(data);
            console.log(data.data.createMarcSubmission.id);
            console.log(typeof data.data.createMarcSubmission.id);

            let conditionCheck;
            if (renderMode == "public") {
              conditionCheck = data.data.validateAndPost.statusCode == 200;
            } else {
              conditionCheck =
                typeof data.data.createMarcSubmission.id == "string";
            }
            if (conditionCheck) {
              lastStatus = { lastStatus: "success" };
            }
            // todo: better error handling from graphql
            else {
              lastStatus = { lastStatus: "fail" };
            }
            console.log(lastStatus);
            setActiveState((appState) => ({
              ...appState,
              ...errorMessage,
              ...newStep,
              ...lastResponse,
              ...lastStatus,
              ...loading,
            }));
          })
          .catch((data) => {
            let loading = { loading: false };
            // console.log(data);
            let lastResponse = { lastAPIResponse: data };
            let lastStatus = { lastStatus: "fail" };
            setActiveState((appState) => ({
              ...appState,
              ...errorMessage,
              ...newStep,
              ...lastResponse,
              ...lastStatus,
              ...loading,
            }));
          });
      } else {
        if (newStep.activeStep == steps.length - 1) {
          disableButton();
        } else {
          enableButton();
        }
        setActiveState((appState) => ({
          ...appState,
          ...errorMessage,
          ...newStep,
        }));
      }
    } else {
      setActiveState((appState) => ({
        ...appState,
        ...errorMessage,
      }));
    }
  };

  const handleBack = () => {
    let newStep = { activeStep: appState.activeStep - 1 };
    enableButton();
    setActiveState((appState) => ({
      ...appState,
      ...newStep,
    }));
  };

  const handleChangeForm = (text) => (event) => {
    setActiveState({ ...appState, [text]: event.target.value });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      {/* <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar variant='dense'>
          <Button component={Lnk} to="/" variant="text1"> Home </Button>
          <Button component={Lnk} to="/aboutus" variant="text1"> About Us </Button>
          <Button variant="text1"> FAQs </Button> */}

      {/* <Typography variant="h6" color="inherit" noWrap>
            Benefits Inc.
          </Typography> */}

      {/* </Toolbar>
      </AppBar> */}

      <div id="text_divs" style={{ paddingTop: 20, paddingBottom: 10 }}></div>

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper elevation={5} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Submit Information
          </Typography>
          <Stepper activeStep={appState.activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {appState.activeStep === steps.length ? (
            // console.log(appState.lastAPIResponse),
            // console.log("data above"),
            <ConfirmSubmit appState={appState} />
          ) : (
            <React.Fragment>
              {getStepContent({
                step: appState.activeStep,
                state: appState,
                fcn: handleChangeForm,
              })}
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {showCaptcha({ step: appState.activeStep })}
              </div>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {appState.activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                  disabled={appState.disabledButton}
                >
                  {appState.activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>

              {appState.activeStep === steps.length - 1 && (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "30px",
                  }}
                >
                  <Typography variant="body2">
                    By clicking submit, you agree to our{" "}
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        enableModal();
                      }}
                    >
                      Terms of Service
                    </Link>
                    , and to FreeThrow LLC using your name to sign relevant
                    public benefit application forms on your behalf.
                  </Typography>
                </div>
              )}
              <Dialog
                open={appState.termsModal}
                onClose={disableModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Terms of Service
                </DialogTitle>
                <DialogContent>
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {terms.split("<br/>").join("\n")}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={disableModal}>Close</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          )}
        </Paper>
        {/* {Object.keys(appState).map((key) => (
          <li> {key} </li>
        ))}{" "} */}
        {/* {appState.activeStep} */}
      </Container>
      {renderMode === "public" && <BottomBar />}
    </ThemeProvider>
  );
}
